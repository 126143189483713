import React, { useState, useEffect } from 'react';

const BalanceSheet = ({ user_type }) => {
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [filter, setFilter] = useState('');
  const [dateRange, setDateRange] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [totalAmounts, setTotalAmounts] = useState({
    today: 0,
    yesterday: 0,
    last3Days: 0,
    thisWeek: 0,
    thisMonth: 0,
    thisYear: 0,
    custom: 0,
  });

  const itemsPerPage = 20;

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await fetch('https://bijuwarsp.bluebugsoft.com/backend/get_sessions.php');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        data.sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
        setSessions(data);
        applyFilters(data);
        calculateTotalAmounts(data);
      } catch (error) {
        console.error('Error fetching sessions:', error);
        setError(error.message || 'An error occurred while fetching sessions.');
      }
    };

    fetchSessions();
  }, []);

  const calculateTotalAmounts = (data) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const threeDaysAgo = new Date(today);
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay());
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    const totals = {
      today: 0,
      yesterday: 0,
      last3Days: 0,
      thisWeek: 0,
      thisMonth: 0,
      thisYear: 0,
      custom: 0,
    };

    data.forEach(session => {
      const sessionDate = new Date(session.start_time);
      const totalPrice = parseFloat(session.total_price);

      if (sessionDate >= today) totals.today += totalPrice;
      if (sessionDate >= yesterday && sessionDate < today) totals.yesterday += totalPrice;
      if (sessionDate >= threeDaysAgo) totals.last3Days += totalPrice;
      if (sessionDate >= startOfWeek) totals.thisWeek += totalPrice;
      if (sessionDate >= startOfMonth) totals.thisMonth += totalPrice;
      if (sessionDate >= startOfYear) totals.thisYear += totalPrice;
    });

    setTotalAmounts(totals);
  };

  const applyFilters = (sessionsToFilter) => {
    let filtered = sessionsToFilter;

    const now = new Date();
    let start = new Date('1900-01-01');
    let end = now;

    switch (dateRange) {
      case 'week':
        start = new Date();
        start.setDate(start.getDate() - 7);
        break;
      case 'month':
        start = new Date();
        start.setMonth(start.getMonth() - 1);
        break;
      case '3months':
        start = new Date();
        start.setMonth(start.getMonth() - 3);
        break;
      case '6months':
        start = new Date();
        start.setMonth(start.getMonth() - 6);
        break;
      case 'year':
        start = new Date();
        start.setFullYear(start.getFullYear() - 1);
        break;
      case 'custom':
        start = new Date(startDate);
        end = new Date(endDate);
        break;
      default:
        start = new Date('1900-01-01');
        end = now;
    }

    filtered = filtered.filter(session => {
      const sessionStartTime = new Date(session.start_time);
      return sessionStartTime >= start && sessionStartTime <= end;
    });

    filtered = filtered.filter(session =>
      session.name.toLowerCase().includes(filter.toLowerCase())
    );

    setFilteredSessions(filtered);
    calculateCustomTotal(filtered);
  };

  const calculateCustomTotal = (filteredData) => {
    const customTotal = filteredData.reduce((sum, session) => sum + parseFloat(session.total_price), 0);
    setTotalAmounts(prev => ({ ...prev, custom: customTotal }));
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    applyFilters(sessions);
    setCurrentPage(1);
  };

  const handleDateRangeChange = (e) => {
    const range = e.target.value;
    setDateRange(range);
    if (range !== 'custom') {
      applyFilters(sessions);
      setCurrentPage(1);
    }
  };

  const handleCustomDateConfirm = () => {
    if (startDate && endDate) {
      applyFilters(sessions);
      setCurrentPage(1);
    } else {
      setError('Please select both start and end dates for the custom range.');
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteSession = async (sessionId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this session? The deleted session would be added to trash and would be removed automatically after 30 days. Contact Bluebug Software for assisstance.');
    
    if (!confirmDelete) return;
    
    try {
      const response = await fetch(`https://bijuwarsp.bluebugsoft.com/backend/delete_session.php?id=${sessionId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete session.');
      }

      const updatedSessions = sessions.filter(session => session.id !== sessionId);
      setSessions(updatedSessions);
      applyFilters(updatedSessions);
    } catch (error) {
      console.error('Error deleting session:', error);
      setError('An error occurred while deleting the session.');
    }
  };

  const formatPrice = (price) => {
    const numericPrice = parseFloat(price);
    return isNaN(numericPrice) ? '0.00' : numericPrice.toFixed(2);
  };

  const toNepaliTime = (dateString) => {
    const date = new Date(dateString);
    const offset = 5.75 * 60 * 60 * 1000;
    return new Date(date.getTime() + offset).toLocaleString();
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentSessions = filteredSessions.slice(startIndex, startIndex + itemsPerPage);

  const totalPages = Math.ceil(filteredSessions.length / itemsPerPage);

  const renderPaginationButtons = () => {
    const buttons = [];
    const range = 2;

    buttons.push(
      <button
        key={1}
        className={`page-button ${currentPage === 1 ? 'active' : ''}`}
        onClick={() => handlePageChange(1)}
      >
        1
      </button>
    );

    if (currentPage > 4) {
      buttons.push(<span key="ellipsis1">...</span>);
    }

    for (let i = Math.max(2, currentPage - range); i < currentPage; i++) {
      buttons.push(
        <button
          key={i}
          className="page-button"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (currentPage !== 1 && currentPage !== totalPages) {
      buttons.push(
        <button key={currentPage} className="page-button active">
          {currentPage}
        </button>
      );
    }

    for (let i = currentPage + 1; i <= Math.min(totalPages - 1, currentPage + range); i++) {
      buttons.push(
        <button
          key={i}
          className="page-button"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    if (currentPage < totalPages - 3) {
      buttons.push(<span key="ellipsis2">...</span>);
    }

    if (totalPages > 1) {
      buttons.push(
        <button
          key={totalPages}
          className={`page-button ${currentPage === totalPages ? 'active' : ''}`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className='overflow-y page-overflow page-balance-sheet'>
      <h2 className='section-heading'>Balance Sheet</h2>
      <p className='sub-heading'>Filter the data by Name or by Time period.</p>

      <div className='total-amounts-summary'>
        <h3>Total Amounts</h3>
        <div className="amounts-grid">
          <div className="amount-item">
            <span className="amount-label">Today:</span>
            <span className="amount-value">Rs. {formatPrice(totalAmounts.today)}</span>
          </div>
          <div className="amount-item">
            <span className="amount-label">Last 3 Days:</span>
            <span className="amount-value">Rs. {formatPrice(totalAmounts.last3Days)}</span>
          </div>
          <div className="amount-item">
            <span className="amount-label">This Month:</span>
            <span className="amount-value">Rs. {formatPrice(totalAmounts.thisMonth)}</span>
          </div>
          <div className="amount-item">
            <span className="amount-label">This Year:</span>
            <span className="amount-value">Rs. {formatPrice(totalAmounts.thisYear)}</span>
          </div>
          <div className="amount-item">
            <span className="amount-label">Custom Range:</span>
            <span className="amount-value">Rs. {formatPrice(totalAmounts.custom)}</span>
          </div>
        </div>
      </div>

      <div className='select-filter'>
        <input
          type="text"
          placeholder="Filter by name..."
          value={filter}
          onChange={handleFilterChange}
        />
        <select value={dateRange} onChange={handleDateRangeChange}>
          <option value="">Select Date Range</option>
          <option value="week">Last Week</option>
          <option value="month">Last Month</option>
          <option value="3months">Last 3 Months</option>
          <option value="6months">Last 6 Months</option>
          <option value="year">Last Year</option>
          <option value="custom">Custom Range</option>
        </select>
        {dateRange === 'custom' && (
          <div className="custom-date-range">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button onClick={handleCustomDateConfirm} className="confirm-date-button">
              Confirm Date Range
            </button>
          </div>
        )}
      </div>

      {error && <p className="error">{error}</p>}

      <table>
        <thead>
          <tr>
            <th>SN</th>
            <th>Name</th>
            <th>Adults</th>
            <th>Kids</th>
            <th>Total Price</th>
            <th>Start Time</th>
            <th>End Time</th>
            {user_type === '1' && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {currentSessions.length > 0 ? (
            currentSessions.map((session, index) => (
              <tr key={session.id}>
                <td>{startIndex + index + 1}</td>
                <td>{session.name}</td>
                <td>{session.adults}</td>
                <td>{session.kids}</td>
                <td>{formatPrice(session.total_price)}</td>
                <td>{toNepaliTime(session.start_time)}</td>
                <td>{toNepaliTime(session.end_time)}</td>
                {user_type === '1' && (
                  <td>
                    <button
                      onClick={() => handleDeleteSession(session.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={user_type === '1' ? 8 : 7}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div className="pagination">
          {renderPaginationButtons()}
        </div>
      )}

      <style>
        {`
          .user-type {
            margin-bottom: 20px;
            font-size: 16px;
            font-weight: bold;
          }

          .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0;
          }
          
          .page-button {
            background-color: #007bff;
            border: none;
            color: white;
            padding: 10px;
            margin: 0 5px;
            cursor: pointer;
            border-radius: 4px;
          }
          
          .page-button.active {
            background-color: #0056b3;
          }
          
          .delete-button {
            background-color: #dc3545;
            border: none;
            color: white;
            padding: 5px 10px;
            cursor: pointer;
          }
          
          .error {
            color: red;
            font-weight: bold;
          }

           .total-amounts-summary {
            border-radius: 4px;
            padding: 20px;
            margin-bottom: 20px;
          }

          .total-amounts-summary h3 {
            margin-top: 0;
            margin-bottom: 15px;
            text-align: center;
            font-size: 1.2em;
            color: #fff;
          }

          .amounts-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 15px;
          }

          .amount-item {
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 4px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
          }

          .amount-label {
            font-weight: bold;
            margin-bottom: 5px;
            color: #fff;
          }

          .amount-value {
            font-size: 1.1em;
            color: #fff;
          }

          .confirm-date-button {
            background-color: #28a745;
            color: white;
            border: none;
            padding: 5px 10px;
            border-radius: 4px;
            cursor: pointer;
          }

          .confirm-date-button:hover {
            background-color: #218838;
          }

          .pagination span {
            margin: 0 5px;
          }
        `}
      </style>
    </div>
  );
};

export default BalanceSheet;